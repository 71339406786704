<template>
  <div class="row">
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="col-md-12 my-4" v-if="!loading">
      <div class="contains-date-search">
        <div class="date-range">
          <el-date-picker
            v-model="dateData"
            type="daterange"
            @change="searchSpecificDate"
            value-format="yyyy-MM-dd"
            start-placeholder="From"
            end-placeholder="To"
            name="pick-date"
            popper-class="notranslate"></el-date-picker>
        </div>
        <div class="form_contain">
          <el-button
            type="primary"
            @click="exportBundleHistory"
            :loading="exportLoading"
            :disabled="disableButton"
            class="export-button"
            >Export</el-button
          >
          <el-input
            placeholder="search by name or amount"
            class="search_item"
            style="height: 40px !important"
            v-model="search">
            <i
              slot="prefix"
              style="padding-to: 2em"
              class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <el-table
        :data="displayData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100%"
        :empty-text="'No Data Available'"
        height="55vh">
        <el-table-column label="Name" prop="name" width="name">
        </el-table-column>
        <el-table-column label="Phone" prop="phone" width="name">
        </el-table-column>
        <el-table-column label="Amount" width="amount" prop="amount">
        </el-table-column>
        <el-table-column label="Bundle Type" width="amount" prop="type">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1" style="color: #00b4d8">SMS</span>
            <span v-else style="color: #f7921c">Voice</span>
          </template>
        </el-table-column>
        <el-table-column label="Balance" prop="balance" width="">
        </el-table-column>
        <el-table-column label="Date" width="">
          <template slot-scope="scope">
            {{ moment(scope.row.created_at).format("ll hh:mm:ss A") }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import appConfig from "@/app.config";
  import downloadcsv from "@/helpers/downloadcsv.js";

  export default {
    page: {
      title: "Bundle history",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        loading: false,
        tableData: [],
        page: 1,
        pageSize: 20,
        total: 0,
        search: "",
        dateData: [],
        exportLoading: false,
      };
    },
    computed: {
      searchingTableData() {
        if (!this.search) {
          this.total = this.tableData.length;
          return this.tableData;
        }
        this.page = 1;
        return this.tableData.filter(
          (data) =>
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.amount == this.search
        );
      },
      displayData() {
        this.total = this.searchingTableData.length;
        return this.searchingTableData.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      disableButton() {
        if (this.tableData.length < 1) {
          return true;
        }
        return false;
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      searchSpecificDate() {
        let payload = {
          from: this.dateData[0],
          to: this.dateData[1],
        };
        this.loading = true;
        store
          .dispatch("admin/reporting/getBundleHistory", payload)
          .then((res) => {
            this.tableData = res;
          })
          .finally(() => (this.loading = false));
      },
      exportBundleHistory() {
        this.exportLoading = true;
        let payload = {
          from: this.dateData[0],
          to: this.dateData[1],
        };
        store
          .dispatch("admin/reporting/exportBundleHistory", payload)
          .then((response) => {
            downloadcsv(
              response.data,
              `bundle_history_from_${this.dateData[0]}_to_${this.dateData[1]}.csv`
            );
          })
          .catch(() => {
            this.$message.error(
              "There was a problem exporting the data. Please try again."
            );
          })
          .finally(() => {
            this.exportLoading = false;
          });
      },
    },
    mounted() {
      this.loading = true;
      store
        .dispatch("admin/reporting/getBundleHistory", {
          to: "",
          from: "",
        })
        .then((res) => (this.tableData = res))
        .finally(() => (this.loading = false));
    },
  };
</script>

<style scoped>
  .contains-date-search {
    display: flex;
    justify-content: space-between;
  }

  .date-range {
    margin: 0 auto;
  }

  .form_contain {
    display: flex;
    gap: 1rem;
  }

  .export-button:disabled {
    background-color: #e6e6e6;
    color: #999999;
    border-color: #e6e6e6;
  }
</style>
